.cart_totals{
    width: 320px;
    float: right;
    padding: 25px;
    border: 1px solid #eee;
}
.cart_totals .cart-subtotal {
    border-collapse: separate;
    margin: 0 0 6px;
    padding: 0px;
   /* font-family: "Montserrat", sans-serif;*/
    font-weight: 700;
    color: #1a1b1e;
    font-size: 16px;
    border: none;
 
    width: 100%;
}
.cart_totals .cart-subtotal .subtotalLeft{
    padding-bottom: 10px;
    text-align: left;
}
.cart_totals .cart-subtotal .subtotal{
    padding-bottom: 10px;
    text-align: right;
}