input[type=text], input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}



.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

.avatar {
  margin: auto;
  background: url(./img/login-icon.png);
  width: 180px;
  height: 180px;
}
.login-form{
  /* border: 1px solid #d2d2d2; */
  width: 600px;
  margin: auto;
  margin-top: 30px;
  min-height: 440px;
}
@media (max-width: 768px) {
  .login-form {
    max-width: 96%;
  }
}
