.post-box {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    display: block;
}
.post-box .post-inner {
    background: #fff;
    margin-bottom: 60px;
  
     
}
.post-box .entry-media {
    position: relative;
}
.post-box .entry-media .post-cat {
    position: absolute;
    z-index: 1;
    bottom: -16px;
    left: 40px;
}

.post-box .post-cat a {
   /* font-family: "Montserrat",sans-serif; 43baff */
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    display: inline-block;
    color: #fff;
    background: #005180;
    padding: 10px;
    margin-right: 6px;
}

img {
    height: auto;
    max-width: 100%;
    border-style: none;
    vertical-align: middle;
}

.post-box .inner-post {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    padding: 40px 40px 36px;
    border: 1px solid #e7e7e7;
}
.post-box .entry-meta {
    text-transform: capitalize;
    /* font-family: "Montserrat",sans-serif; */
    font-weight: 600;
    font-size: 14px;
    color: #7141b1;
    margin-bottom: 13px;
}
.post-box .entry-meta > span {
    margin-right: 5px;
    display: inline-block;
}
.post-box .entry-meta a {
    color: #7141b1;
}

.post-box .entry-title {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 36px;
     
}

.post-box .entry-title a {
    color: #1b1d21;
    display: inline-block;
    /* font-family: "Montserrat",sans-serif;*/
    font-weight: 800;
    line-height: 1.2;
    margin: 0 0 20px;
}

.post-box p {
    margin-bottom: 0;
    margin: 0 0 20px;
    color: #6d6d6d;
    /* font-family: "Nunito Sans",sans-serif;*/
    font-size: 16px;
    line-height: 1.875;
    font-weight: 400;
    word-wrap: break-word;
}

.post-box .btn-readmore {
    margin-top: 22px;
    line-height: 1;
    overflow: hidden;
}
.post-box .btn-readmore > a {
    font-size: 14px;
    font-weight: 700;
    color: #005180;
}
.post-box .btn-readmore > a i {
    margin-right: 8px;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
}