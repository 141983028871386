:root {
    --textColor: #005180;
    --textHover: #f06e32; 
  }
  a {
    color: var(--textColor);
  }
  
  a:hover {
    color: var(--textHover);
    text-decoration: none;
  }