.page-pagination {
  margin-top: 60px;
  margin-bottom: 0;
}
.none-style {
  list-style: none;
  padding-left: 0;
}

.page-pagination li {
  display: inline-block;
}

.page-pagination li a, .page-pagination li span {
  font-size: 18px;
  color: #1b1d21;
  background: #fff;
  font-weight: 700;
  height: 40px;
  width: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
  margin-right: 5px;
  border: 1px solid #e7e7e7;
      border-top-color: rgb(231, 231, 231);
      border-right-color: rgb(231, 231, 231);
      border-bottom-color: rgb(231, 231, 231);
      border-left-color: rgb(231, 231, 231);
}

.page-pagination li span, .page-pagination li a:hover, .page-pagination .active a {
  background: #005180;
  color: #fff;
  border-color: #005180;
}
.page-pagination .disabled a {
  background: #dddddd;
  color: #fff;
  border-color: #dddddd;
}

#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  
#react-paginate li {
    display: inline-block;
  }