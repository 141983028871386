.blog-post {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    display: block;
}
.blog-post .post-inner {
    background: #fff;
    margin-bottom: 60px;
  
     
}
.blog-post .entry-media {
    position: relative;
}
.blog-post .entry-media .post-cat {
    position: absolute;
    z-index: 1;
    bottom: -16px;
    left: 40px;
}

.blog-post .post-cat a {
   /* font-family: "Montserrat",sans-serif;*/
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 1;
    display: inline-block;
    color: #fff;
    background: #005180;
    padding: 10px;
    margin-right: 6px;
}

img {
    height: auto;
    max-width: 100%;
    border-style: none;
    vertical-align: middle;
}

.blog-post .inner-post {
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    padding: 40px 40px 36px;
    border: none;
}
.blog-post .entry-meta {
    text-transform: capitalize;
   /* font-family: "Montserrat",sans-serif;*/
    font-weight: 600;
    font-size: 14px;
    color: #7141b1;
    margin-bottom: 13px;
}
.blog-post .entry-meta > span {
    margin-right: 5px;
    display: inline-block;
}
.blog-post .entry-meta a {
    color: #7141b1;
}

.blog-post .entry-title {
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 36px;
     
}

.blog-post .entry-title a {
    color: #1b1d21;
    display: inline-block;
  /*  font-family: "Montserrat",sans-serif;*/
    font-weight: 800;
    line-height: 1.2;
    margin: 0 0 20px;
}

.blog-post p {
    margin-bottom: 0;
    margin: 0 0 20px;
    color: #6d6d6d;
  /*  font-family: "Nunito Sans",sans-serif;*/
    font-size: 16px;
    line-height: 1.875;
    font-weight: 400;
    word-wrap: break-word;
}

.blog-post .btn-readmore {
    margin-top: 22px;
    line-height: 1;
    overflow: hidden;
}
.blog-post .btn-readmore > a {
    font-size: 14px;
    font-weight: 700;
    color: #005180;
}
.blog-post .btn-readmore > a i {
    margin-right: 8px;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
}

.blog-post .entry-footer {
    margin-bottom: 30px;
    padding: 18px 0;
    
    border-bottom: 1px solid #e5e5e5;
}

.blog-post .tagcloud {
    margin: 2px -3px 0;
    float: left;
}

.blog-post .tagcloud a {
    color: #1b1d21;
    background: #f6f6f6;
    font-size: 14px!important;
    font-weight: 600;
    padding: 10px 12px;
    margin: 0 3px;
    margin-bottom: 10px;
    line-height: 1;
    display: inline-block;
    margin-bottom: 0;
}
.blog-post .share-post {
    margin-bottom: 30px;
    text-align: center;
}
.blog-post .share-post a.twit {
    background: #44b1e4;
}
 

.blog-post .share-post a.face {
    background: #4661c5;
}
.blog-post .share-post a.pint {
    background: #ff2e2e;
}
.blog-post .share-post a.linked {
    background: #0073B0;
}
.blog-post .share-post a.reddit {
    background: #FD4507;
}