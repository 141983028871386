.project_filters {
    position: relative;
    width: 100%;
    text-align: center;
    /* margin-bottom: 30px; */
    padding-left: 0;
    background-color: white !important;
    font-weight: 700;
    font-size: 16px;
}
.project_filters ul
{
    margin: 0 0 20px;
}
.project_filters li {
    display: inline-block;
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
}
.project_filters li a {
    position: relative;
    display: inline-block;
    letter-spacing: 1px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    outline: none;
    color: #1b1d21 !important;
    padding-bottom: 10px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
}
.project_filters li a:hover, .project_filters li a.selected, .project_filters li a.actived {
    color: #7141b1 !important;
}
.project_filters li a::after {
  position: absolute;
  height: 3px;
  width: 0%;
  right: 0;
  bottom: 0;
  background-color: #7141b1;
  content: "";
  display: block;
  border-radius: 1.5px;
  -webkit-border-radius: 1.5px;
  -moz-border-radius: 1.5px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
}
.project_filters li a:hover::after, .project_filters li a.selected::after, .project_filters li a.active::after {
  opacity: 1;
  left: 0;
  right: auto;
  width: 100%;
}
/* .project_filters li a.active::before {
  content: "";
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  height: 1px;
  background-color: #000;
} */

.item {
    border: none;
    margin-bottom: 30px;
  }
  
  .item .item-wrap {
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .item .item-wrap:after {
    z-index: 2;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    visibility: hidden;
    opacity: 0;
    transition: .3s all ease-in-out;
  }
  
  .item .item-wrap img {
    transition: .3s transform ease;
    transform: scale(1);
  }
  
  .item .item-wrap > .work-info {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    z-index: 3;
    transform: translateY(-50%);
    color: #fff;
    opacity: 0;
    visibility: hidden;
    margin-top: 20px;
    transition: .3s all ease;
  }
  
  .item .item-wrap > .work-info h3 {
    font-size: 20px;
    margin-bottom: 0;
  }
  
  .item .item-wrap > .work-info span {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .2rem;
  }
  
  .item .item-wrap:hover {
    text-decoration: none;
  }
  
  .item .item-wrap:hover img {
    transform: scale(1.05);
  }
  
  .item .item-wrap:hover:after {
    opacity: 1;
    visibility: visible;
  }
  
  .item .item-wrap:hover .work-info {
    margin-top: 0px;
    opacity: 1;
    visibility: visible;
  }
  .filters a {
    color: #000;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}
.filters a.active {
    color: #7141b1 !important;
    /* text-decoration: underline !important; */
    padding-bottom: 10px !important;
}
.elementor-widget-container
{
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}
.elementor-widget-container p
{
  text-align: center;
}
.ot-heading > span
{
  font-size: 14px;
  font-weight: 800;
  color: #7141b1;
  position: relative;
  display: inline-block;
  margin-bottom: 2px;
  text-transform: uppercase;
}
.ot-heading h2 {
  margin-bottom: 0;
  line-height: 48px;
  font-weight: 800;
}
.elementor-widget-wrap {
  position: relative;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
.elementor-heading-title {
  color: #636363;
  /*font-family: "Red Hat Display",Sans-serif;*/
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  letter-spacing: 1px;
}
.elementor-heading-title-content {
  color: #1b1d21;
  /*font-family: "Montserrat",sans-serif;*/
  font-weight: 800;
}
.otf-social-share {
  text-align: right;
}
.otf-social-share a {
  display: inline-block;
  font-size: 18px;
  text-align: center;
  padding: 10px;
  margin-right: 10px;
  cursor: pointer;
  line-height: 1;
  margin-right: 5px;
  background-color: #222;
  color: #fff;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
}
.otf-social-share.shape-circle a {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
.otf-social-share a.share-facebook {
  background-color: #4661c5;
}
.otf-social-share a.share-twitter {
  background-color: #44b1e4;
}
.otf-social-share a.share-pinterest {
  background-color: #ff2e2e;
}
.otf-social-share a.share-email {
  background-color: #E84231;
}

.otf-social-share a i {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
}
.fa-facebook-f::before {
  content: "\f39e";
}
.elementor-image
{
  height: auto;
  max-width: 100%;
  border: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}